export const Bio = {
    name: "Adwaith S",
    roles: [
        "Full Stack Developer",
        "Flutter Developer",
        "Dotnet Developer",
        "Programmer",
    ],
    description:
        "Experienced full-stack engineer proficient in ASP.NET, Angular, and Flutter, with expertise spanning both web and mobile application development as well as backend systems. Demonstrates strong knowledge in API development, front-end frameworks, and mobile app development using cutting-edge technologies. Skilled in delivering innovative solutions across various platforms and committed to maintaining high standards of quality and efficiency in development processes.",
    github: "https://bit.ly/adwaith_github",
    resume: "https://bit.ly/adwaith_resume",
    linkedin: "https://www.linkedin.com/in/adwaithnow",
};

export const skills = [
    {
        title: "Frontend",
        skills: [
            {
                name: "Angular",
                image:
                    "https://upload.wikimedia.org/wikipedia/commons/f/f7/Angular_gradient.png",
            },
            {
                name: "Asp.NET MVC",
                image:
                    "https://upload.wikimedia.org/wikipedia/commons/7/7d/Microsoft_.NET_logo.svg",
            },
            {
                name: "Flutter",
                image:
                    "https://cdn-images-1.medium.com/max/1200/1*5-aoK8IBmXve5whBQM90GA.png",
            },
            {
                name: "Dart",
                image:
                    "https://upload.wikimedia.org/wikipedia/commons/a/a2/Dart_programming_language_logo_icon.svg",
            },
            {
                name: "TypeScript",
                image:
                    "https://upload.wikimedia.org/wikipedia/commons/4/4c/Typescript_logo_2020.svg",
            },
            {
                name: "JavaScript",
                image:
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
            },
            {
                name: "HTML",
                image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
            },
            {
                name: "CSS",
                image:
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png",
            },

            {
                name: "Bootstrap",
                image:
                    "https://getbootstrap.com/docs/5.3/assets/brand/bootstrap-logo-shadow.png",
            },

        ],
    },
    {
        title: "Backend",
        skills: [
            {
                name: "Node Js",
                image: "https://nodejs.org/static/images/logo.svg",
            },
            {
                name: ".NET",
                image: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Microsoft_.NET_logo.svg",
            },
            {
                name: "GraphQL",
                image: "https://www.vectorlogo.zone/logos/graphql/graphql-icon.svg",
            },
            {
                name: "C#",
                image:
                    "https://upload.wikimedia.org/wikipedia/commons/b/bd/Logo_C_sharp.svg",
            },
            {
                name: "MySQL",
                image:
                    "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg",
            },
            {
                name: "MS SQL Server",
                image:
                    "https://upload.wikimedia.org/wikipedia/de/8/8c/Microsoft_SQL_Server_Logo.svg",
            },
            {
                name: "Json",
                image: "https://upload.wikimedia.org/wikipedia/commons/c/c9/JSON_vector_logo.svg",
            },
            {
                name: "Entity Framework",
                image: "https://codeopinion.com/wp-content/uploads/2017/10/Bitmap-MEDIUM_Entity-Framework-Core-Logo_2colors_Square_Boxed_RGB.png",
            }, {
                name: "Firebase",
                image: "https://www.vectorlogo.zone/logos/firebase/firebase-icon.svg",
            },
        ],
    },

    {
        title: "Others",
        skills: [
            {
                name: "Git",
                image:
                    "https://upload.wikimedia.org/wikipedia/commons/e/e0/Git-logo.svg",
            },
            {
                name: "GitHub",
                image:
                    "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
            },
            {
                name: "Docker",
                image:
                    "https://upload.wikimedia.org/wikipedia/en/f/f4/Docker_logo.svg",
            },
            {
                name: "Android Studio",
                image:
                    "https://upload.wikimedia.org/wikipedia/commons/c/c1/Android_Studio_icon_%282023%29.svg",
            },
            {
                name: "Visual Studio",
                image:
                    "https://upload.wikimedia.org/wikipedia/commons/2/2c/Visual_Studio_Icon_2022.svg",
            },

            {
                name: "VS Code",
                image:
                    "https://upload.wikimedia.org/wikipedia/commons/9/9a/Visual_Studio_Code_1.35_icon.svg",
            },

            {
                name: "Postman",
                image:
                    "https://voyager.postman.com/logo/postman-logo-icon-orange.svg",
            },

        ],
    },


];

export const experiences = [

    {
        id: 2,
        img: "https://firebasestorage.googleapis.com/v0/b/mail-2167f.appspot.com/o/Glitchh.png?alt=media&token=eb93edaf-a5e1-4de7-a578-747a51822525",
        role: "Flutter Developer",
        company: "Glitchh",
        date: "Jan 2023 - Present",
        desc: "Led the development of \"Glitchh,\" a social media app on Flutter. Integrated Firebase for seamless user authentication, behavior analytics, and engagement through push notifications. Implemented dynamic linking for organic growth. Collaborated with UI/UX designers for consistent interfaces. Expertly integrated APIs for robust data exchange, including RESTful and GraphQL. Multimedia features include video playback, editing, gallery management, and efficient image handling. Enhanced content display with Markdown support and real-time interactions via WebSocket. Developed innovative UI with custom widgets and animations. Introduced AR elements with 'model_viewer_plus.' Implemented deep linking for targeted user experiences. Utilized the provider package for efficient state management, reducing rebuilds and ensuring a scalable app structure.",
        skills: [
            "Flutter",
            "Provider",
            "Firebase",
            "GraphQL",
            "AR",
            "Deeplinking",
            "Multimedia Integration"
        ],
    },
    {
        id: 1,
        img: "https://firebasestorage.googleapis.com/v0/b/mail-2167f.appspot.com/o/UST_Wikipedia_Logo.png?alt=media&token=6ea70921-f93a-4cbb-8673-a73210500e5d",
        role: "Developer I - Software Engineering",
        company: "UST",
        date: "March 2022 - February 2023",
        desc: "Created BMCIT (BookMyChairInTrain), an Angular app with an ASP.NET API backend facilitating train ticket booking, including seat selection. Developed a job seeker application utilizing ASP.NET MVC, Entity Framework, and LINQ. Implemented Json documents for efficient storage in BMCIT. Ensured high-quality deliverables through rigorous unit testing, integration testing, and user acceptance testing. Played a key role in troubleshooting and resolving production environment issues. Utilized Git for effective version control, contributing to a streamlined development process.",
        skills: [
            "ASP.NET",
            "Angular",
            "NodeJs",
            "Json",
            "LINQ",
        ],
    },
    {
        id: 0,
        img: "https://firebasestorage.googleapis.com/v0/b/mail-2167f.appspot.com/o/Design%20Crewz%20(1).png?alt=media&token=5ced0453-cac6-426a-b82a-0dbd020d9491",
        role: "Software Developer",
        company: "DesignCrewz WebStudio",
        date: "March 2020 - March 2022",
        desc: "Developed impactful mobile applications utilizing Flutter, collaborating closely with clients to gather requirements and design app functionality. Implemented key features for diverse user roles including sales representatives, customers, and delivery drivers. Expertly debugged and resolved code issues, ensuring seamless app operation. Designed and executed functionalities such as customer management, inventory tracking, sales reporting, order monitoring, and messaging, contributing to a comprehensive and user-friendly mobile experience.",
        skills: [
            "Flutter",
            "NodeJs",
            "Firebase",
            "AWS",
            "MongoDB",
        ],
    },


];

export const education = [
    {
        id: 1,
        img: "https://firebasestorage.googleapis.com/v0/b/mail-2167f.appspot.com/o/SGE.png?alt=media&token=eeea6552-cfa5-4fc7-80c6-c7aed3592146",
        school: "SAINTGITS COLLEGE OF ENGINEERING (AUTONOMOUS)",
        date: "August 2020 - August 2022",
        grade: "9.5 CGPA",
        desc: "I have achieved a Master of Computer Application degree from Saingits College of Engineering (Autonomous) with a CGPA of 9.5, the highest in the college.",
        degree: "Master of Computer Application",
    },
    {
        id: 0,
        img: "https://firebasestorage.googleapis.com/v0/b/mail-2167f.appspot.com/o/SBC.webp?alt=media&token=56852d67-8db0-4400-9c13-500846ce9a0c",
        school: "ST.BERCHMANS COLLEGE (AUTONOMOUS)",
        date: "July 2017 - March 2020",
        grade: "7.49 CGPA",
        desc: "I've obtained a Bachelor of Computer Application degree from St. Berhcmans College Autonomous and received the Proficiency Award during S5.",
        degree: "Bachelor of Computer Application",
    },


];

export const projects = [
    {
        id: 0,
        title: "Glitchh.in",

        date: "Apr 2023 - May 2023",
        description:
            "Led the development of \"Glitchh,\" a social media app on Flutter. Integrated Firebase for seamless user authentication, behavior analytics, and engagement through push notifications. Implemented dynamic linking for organic growth. Collaborated with UI/UX designers for consistent interfaces. Expertly integrated APIs for robust data exchange, including RESTful and GraphQL. Multimedia features include video playback, editing, gallery management, and efficient image handling. Enhanced content display with Markdown support and real-time interactions via WebSocket. Developed innovative UI with custom widgets and animations. Introduced AR elements with 'model_viewer_plus.' Implemented deep linking for targeted user experiences. Utilized the provider package for efficient state management, reducing rebuilds and ensuring a scalable app structure.",
        image:
            "assets/images",
        tags: ["React Js", "MongoDb", "Node Js", "Express Js", "Redux"],
        category: "web app",
        github: "",
        webapp: "",

    },

];

export const TimeLineData = [
    { year: 2020, text: "Started my journey" },
];